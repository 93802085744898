import React from "react";

const BreadCrumbSchema = () => (
    <React.Fragment>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(
                    {
                        "@context": "https://schema.org/",
                        "@type": "BreadcrumbList",
                        "itemListElement": [{
                            "@type": "ListItem",
                            "position": 1,
                            "name": "About Us",
                            "item": "https://www.hoteltwintowers.com/about-us"
                        }, {
                            "@type": "ListItem",
                            "position": 2,
                            "name": "Room and Suits",
                            "item": "https://www.hoteltwintowers.com/rooms-and-suites"
                        }, {
                            "@type": "ListItem",
                            "position": 3,
                            "name": "Bar and Restaurant",
                            "item": "https://www.hoteltwintowers.com/bar-and-restaurant"
                        }, {
                            "@type": "ListItem",
                            "position": 4,
                            "name": "Events",
                            "item": "https://www.hoteltwintowers.com/#events"
                        }, {
                            "@type": "ListItem",
                            "position": 5,
                            "name": "Banquet Halls in Shimla",
                            "item": "https://www.hoteltwintowers.com/banquet-halls"
                        }, {
                            "@type": "ListItem",
                            "position": 6,
                            "name": "Conference Halls",
                            "item": "https://www.hoteltwintowers.com/conference-hall"
                        }]
                    }
                )
            }}
        />
    </React.Fragment>
);


export default BreadCrumbSchema;